import React, { useContext } from 'react';
// import link
import { Link } from 'react-router-dom';
// import icons
import { IoMdAdd, IoMdClose, IoMdRemove } from 'react-icons/io';
// import cart context
import { CartContext } from '../contexts/CartContext';
import constants from '../contexts/constants';

const CartItem = ({ item }) => {
  const { removeFromCart, increaseAmount, decreaseAmount } = useContext(CartContext);
  //destructure item
  const { productId, productCode, productName, productDescription, productCategory, productImage, productPrice, productQuantity, amount } = item;
  

  return (
    <div className='flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light 
    text-gray-500'>
      <div className='w-full min-h-[150px] flex items-center gap-x-4'>
        {/* image */}
        <Link to={`/product/${productId}`}>
        <img className='max-w-[80px]' src={productImage ? `${constants.ENDPOINT}/${productImage}` : ''} alt='' />
        </Link>
        <div className='w-full flex flex-col'>
          {/* title & remove icon */}
            <div className='flex justify-between mb-2'>
            {/* title */}
            <Link
              to={`/product/${productId}`}
              className='text-sm uppercase font-medium max-w-[240px] 
              text-black hover:underline'
            >
              {productName}
            </Link>
            {/* remove icon */}
            <div onClick={() => removeFromCart(productId)} className='text-xl cursor-pointer'>
              <IoMdClose className='text-gray-500 hover:text-red-500 transition' />
            </div>
          </div>
          <div className=' flex gap-x-2 h-[36px] text-sm'>
            {/* qty */}
            <div className='flex flex-1 max-w-[100px] items-center h-full border
            text-black font-medium'>
              {/* minus icon */}
              <div 
              onClick={() => decreaseAmount(productId)}
              className='flex-1 h-full flex justify-center items-center cursor-pointer'
              >
                <IoMdRemove />
              </div>
              {/* amount */}
              <div className='h-full flex justify-center items-center px-2'>{amount}</div>
              {/* plus icon */}
              <div 
              onClick={() => increaseAmount(productId)} 
              className='flex-1 h-full flex justify-center items-center cursor-pointer'
              >
                <IoMdAdd />
              </div>
            </div>
            {/* item price */}
            <div className='flex-1 flex items-center justify-around'>$ {productPrice}</div>
            {/* final price */}
            {/* price at 2 decimals */}
            <div className='flex-1 flex justify-end items-center text-black font-medium'>
              {`$ ${parseFloat(productPrice * amount).toFixed(2)}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
