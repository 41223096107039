export const PRODUCTS = [
  { name: "Shirts", link: "/product/shirts" },
  { name: "Jackets", link: "/product/jackets" },
  { name: "Bags", link: "/product/bags" }
];
export const COMPANY = [
  { name: "About us", link: "/about" }
];
export const SUPPORT = [
  { name: "Contact Us", link: "/contact" }
];

export const Icons = [
  { name: "logo-facebook", link: "/" },
  { name: "logo-twitter", link: "/" },
  { name: "logo-instagram", link: "/" }
];
